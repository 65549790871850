import { useContext, useEffect, useState } from "react"
import CustomDropdown from "./CustomDropDown"
import { useCart } from "react-use-cart"
import { FaTrash } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"
import { FaMinus } from "react-icons/fa"
import "aos/dist/aos.css"
import AOS from "aos"
import { usePromo } from "./Initializer"
import { sendMessageToTelegram } from "../telegramBot"
import trackUserAction from "../track_action"
import { PopupContext } from "../App"
AOS.init({
  delay: 100, // Default delay in milliseconds for all elements
  offset: 100,
})

const ProductCard = ({ product, set_show_loading }) => {
  const {currency, setCurrency} = useContext(PopupContext);

  const priceObj = 1;
  console.log(priceObj)
  
  const [editedPrice, setEditedPrice] = useState(priceObj)
  const [sizePrice, setSizePrice] = useState(priceObj)
  const [multiplier, setMultiplier] = useState(2)
  const { promo, promoAmount } = usePromo()

  useEffect(()=>{
    setEditedPrice(1)
  },[currency])
  

  const {
    addItem,
    removeItem,
    updateItemQuantity,
    items,
    isEmpty,
    totalItems,
    cartTotal,
    emptyCart,
  } = useCart()
  const sizeOptions = [
    {
      value: "option1",
      label: "Small",
      price: product.originalPrice - 16,
      icon: "/size_small.png",
    },
    {
      value: "option2",
      label: "Medium",
      price: product.originalPrice,
      icon: "/size_medium.png",
    },
    {
      value: "option3",
      label: "Large",
      price: product.originalPrice + 14,
      icon: "/size_large.png",
    },
  ]

  const crustOptions = [
    {
      value: "crust1",
      label: "Pan",
      price: sizePrice,
      icon: "/crust/crust_pan.png",
    },
    {
      value: "crust2",
      label: "Thin N Crispy",
      price: sizePrice,
      icon: "/crust/crust_thin_n_crispy.png",
    },
    {
      value: "crust3",
      label: "Handcrafted Sourdough",
      price: sizePrice + multiplier,
      icon: "/crust/crust_handcrafted_sourdough.png",
    },
    {
      value: "crust4",
      label: "Stuffed Crust",
      price: sizePrice + multiplier * 2,
      icon: "/crust/crust_stuffed_crust.png",
    },
  ]






  const handleAddItem = () => {
    trackUserAction("User Added Product To Cart", true)
    trackUserAction(`Product: ${product.name}`, false)
    const item = {
      id: product.id,
      // name: product.name,
      // price: editedPrice,
      // img: product.src,
      // quantity: 1,
      // description: product.description,
      // toppings: "none",
    }
    set_show_loading(true)
    setTimeout(() => set_show_loading(false), 1000) // Hide overlay after 2 seconds
    setTimeout(() => {
      addItem(item)
    }, 500)

    console.log(
      `items:${JSON.stringify(
        items
      )}, IsEmpty:${isEmpty}, TotalItems: ${totalItems}, cartTotal:${cartTotal}, emptyCart:${emptyCart}`
    )
  }

  const isInCart = (productId) => {
    return items.some((item) => item.id === productId)
  }

  const itemInCart = items.find((cartItem) => cartItem.id === product.id)
  const itemQuantity = itemInCart ? itemInCart.quantity : 0

  const handleRemoveItem = () => {
    removeItem(product.id)
    set_show_loading(true)
    setTimeout(() => set_show_loading(false), 500) // Hide overlay after 2 seconds
  }

  const handleUpdateQuantity = (newQuantity) => {
    updateItemQuantity(product.id, newQuantity)
  }
  return (
    <div className="flex flex-col w-full mb-4 bg-white border shadow border-slate-100 rounded-xl">
      <div className="flex flex-col w-full p-2">
        <span className="font-semibold">{product.name}</span>
        <span className="text-xs text-gray-500 ">{product.description}</span>
      </div>
      <div className="flex justify-between w-full"></div>
      <div className="relative w-full overflow-hidden rounded-xl">
        <img
          className="object-cover w-full h-44 "
          src={`/images/${product.file_path}`}
          alt=""
        />
        <div className="absolute bottom-0 left-0 right-0 w-full h-20 opacity-30 bg-gradient-to-b from-transparent to-black"></div>
        <div className="absolute top-0 bottom-0 left-0 right-0 z-50">
          <div className="absolute flex flex-col items-end right-3 bottom-2"></div>
          {/* <div className="absolute flex items-center p-1 bg-white rounded bg-opacity-65 left-2 bottom-2">
            <span className="mr-1 text-sm font-semibold ">Cutomize</span>
            <div className="flex items-center justify-center w-5 h-5 rounded bg-prim_red">
              <span className="font-bold text-white ">+</span>
            </div>
          </div> */}
        </div>
      </div>
      <div className="flex items-center justify-between p-2">
        <div className="flex items-end">
          {/* <div className="flex flex-col">
            <span className="mr-2 text-xs text-gray-500">Choose Size</span>
            <CustomDropdown
              options={sizeOptions}
              onChange={handleChangeSize}
              selectedOption={selectedOption}
            />
          </div>
          <div className="flex flex-col">
            <span className="text-xs text-gray-500">Choose Crust</span>
            <CustomDropdown
              options={crustOptions}
              onChange={handleChangeCrust}
              selectedOption={selectedCrustOption}
            />
          </div> */}
          <span className="mr-2 text-xl font-bold leading-3">
            {product.product_variation[0].price}.00{" "}
          </span>
          <span className="text-sm font-bold leading-3 line-through opacity-75">
            {/* {editedPrice}.00 {priceObj.currency} */}
          </span>
        </div>

        <div className="flex items-center w-fit ">
          {itemInCart ? (
            itemQuantity > 1 ? (
              <>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-lg bg-prim_dark"
                  onClick={() => handleUpdateQuantity(itemQuantity - 1)}
                >
                  <FaMinus style={{ color: "white", fontSize: "12px" }} />
                </button>
                <span className="mx-2 text-lg font-semibold">
                  {itemQuantity}
                </span>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-lg bg-prim_red"
                  onClick={() => handleUpdateQuantity(itemQuantity + 1)}
                >
                  <FaPlus style={{ color: "white", fontSize: "12px" }} />
                </button>
              </>
            ) : (
              <>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-lg bg-prim_dark"
                  onClick={() => handleRemoveItem()}
                >
                  <FaTrash style={{ color: "white", fontSize: "12px" }} />
                </button>
                <span className="mx-2 text-lg font-semibold">
                  {itemQuantity}
                </span>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-lg bg-prim_red"
                  onClick={() => handleUpdateQuantity(itemQuantity + 1)}
                >
                  <FaPlus style={{ color: "white", fontSize: "12px" }} />
                </button>
              </>
            )
          ) : (
            <button
              style={{
                background: `radial-gradient(circle at top left, #F1656A, #EE3A43)`,
              }}
              className="px-8 py-3 text-sm font-bold text-white rounded-lg "
              onClick={handleAddItem}
            >
              + ADD
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductCard
