import { useContext, useEffect, useState } from "react"
import { useCart } from "react-use-cart"
import { PopupContext } from "../App";
import { usePromo } from "./Initializer";
import { sendMessageToTelegram } from "../telegramBot";
import trackUserAction from "../track_action";
import { FaShoppingCart } from 'react-icons/fa';
import { FaMapMarkerAlt } from "react-icons/fa" // Import a marker icon from react-icons
const CartViget = () => {
  const [extended, set_extended] = useState(false)
  const { set_show_popup, setActiveComponent, currency,   set_popup_height} = useContext(PopupContext);
  const {
    addItem,
    removeItem,
    updateItemQuantity,
    items,
    isEmpty,
    totalItems,
    cartTotal,
    emptyCart,
  } = useCart()
  const { promo, promoAmount } = usePromo();
  useEffect(() => {
    if (totalItems > 0) {
      set_extended(true)
    } else {
      set_extended(false)
    }
  }, [totalItems])
  const handleSubmit  = () => {
    set_submit_btn(<div className="ml-2 redinputLoader"></div>);
    setTimeout(()=>{
      set_submit_btn(<span onClick={handleSubmit} className="w-full ml-1 text-sm font-bold text-prim_red">
        選擇地址
      </span>)
    },5000)
    set_popup_height(35);
    set_show_popup(true);
    setActiveComponent("map_man");    
    trackUserAction('User Went To Address Select', true);
  }
  const [submit_btn, set_submit_btn] = useState(              <span onClick={handleSubmit} className="w-full ml-1 text-sm font-bold text-prim_red">
  選擇地址
</span>)
  return (
    <div className={`cusz flex justify-end fixed bottom-0 w-full p-2 `}>
      <div
        style={{
          background: `radial-gradient(circle at top left, #F1656A, #EE3A43)`,
        }}
        className={`cusz relative flex items-center justify-center h-16 transition-all ease-in-out shadow   rounded ${
          extended ? "min-w-full" : "min-w-16"
        }`}
      >
        {extended ? (
          <div className="flex justify-between w-full h-full p-2">
            <div className="flex w-full">
              <div className="flex items-center justify-center flex-shrink-0 h-full mr-1 border-r border-white aspect-square">
              <FaShoppingCart color="white" size={25} />
              </div>
              <div className="flex items-center justify-center w-full h-full border-white rounded">
                {/* <svg
                  stroke="white"
                  fill="white"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  class="CartButton_cartIcon__2kA9L"
                  height="2em"
                  width="2em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      fill="none"
                      d="M0 0h24v24H0z"
                    ></path>
                    <path d="M4 16V4H2V2h3a1 1 0 0 1 1 1v12h12.438l2-8H8V5h13.72a1 1 0 0 1 .97 1.243l-2.5 10a1 1 0 0 1-.97.757H5a1 1 0 0 1-1-1zm2 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm12 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"></path>
                  </g>
                </svg> */}
              <div className="flex flex-col items-center justify-center w-full h-full">
                <div className="flex flex-col w-full px-2 text-sm font-bold text-white">
                  <div className="flex items-center w-full">
                  {/* <div className="flex items-center justify-center w-4 h-4 mr-1 bg-white rounded-full text-prim_red"> */}
                    <span className="mr-1">{totalItems}</span>
                  {/* </div> */}
                  {
                    totalItems > 1 ? (
                      <span> 件</span>
                    ) : (
                      <span> 件</span>
                    )
                  }
                  
                  </div>
                  <span className="w-full">{(cartTotal-((cartTotal/100)*promoAmount)).toFixed(2)} HK$</span>
                </div>
                {/* <span className="text-xs text-white opacity-70">
                  Inclusive of taxes
                </span> */}
              </div>
              </div>
            </div>
            <div className="flex items-center justify-between flex-shrink-0 h-full p-2 transition-all bg-white rounded w-fit ">
              <FaMapMarkerAlt color="red" />
                {submit_btn}
              
            </div>
          </div>
        ) : (
          <>
            <svg
              stroke="white"
              fill="white"
              stroke-width="0"
              viewBox="0 0 24 24"
              class="CartButton_cartIcon__2kA9L"
              height="2em"
              width="2em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  fill="none"
                  d="M0 0h24v24H0z"
                ></path>
                <path d="M4 16V4H2V2h3a1 1 0 0 1 1 1v12h12.438l2-8H8V5h13.72a1 1 0 0 1 .97 1.243l-2.5 10a1 1 0 0 1-.97.757H5a1 1 0 0 1-1-1zm2 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm12 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"></path>
              </g>
            </svg>

            <div className="absolute flex items-center justify-center w-6 h-6 bg-white rounded-full -top-1 -right-1">
              {totalItems}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CartViget
