import React from "react"
import { useCart } from "react-use-cart"
import trackUserAction from "../track_action"
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa"
import { usePromo } from "./Initializer"

const HkProductCard = ({ product, set_show_loading }) => {
  const { promo, promoAmount } = usePromo()
  const {
    addItem,
    removeItem,
    updateItemQuantity,
    items,
    isEmpty,
    totalItems,
    cartTotal,
    emptyCart,
  } = useCart()


  const handleAddItem = () => {
    trackUserAction("User Added Product To Cart", true)
    trackUserAction(`Product: ${product.name}`, false)
    const item = {
      id: product.id,
      name: product.name,
      price: product.product_variations[0].price,
      img: product.file_path,
      quantity: 1,
      description: product.description,
      // toppings: "none",
    }
    set_show_loading(true)
    setTimeout(() => set_show_loading(false), 1000) // Hide overlay after 2 seconds
    setTimeout(() => {
      addItem(item)
    }, 500)

    console.log(
      `items:${JSON.stringify(
        items
      )}, IsEmpty:${isEmpty}, TotalItems: ${totalItems}, cartTotal:${cartTotal}, emptyCart:${emptyCart}`
    )
  }


  const itemInCart = items.find((cartItem) => cartItem.id === product.id)
  const itemQuantity = itemInCart ? itemInCart.quantity : 0

  const handleRemoveItem = () => {
    removeItem(product.id)
    set_show_loading(true)
    setTimeout(() => set_show_loading(false), 500) // Hide overlay after 2 seconds
  }

  const handleUpdateQuantity = (newQuantity) => {
    updateItemQuantity(product.id, newQuantity)
  }
  return (
    <div className="flex items-start justify-between w-full px-2 py-4 my-2 border rounded-xl">
      <div className="flex flex-col w-full mr-2">
        <span className="text-lg font-bold">{product.name}</span>
        <span className="mt-3 text-sm line-through text-neutral-600">HK$ {(product.product_variations[0].price).toFixed(2)}</span>
        <span className="mt-1 mb-3 font-bold text-neutral-600">HK$ {(product.product_variations[0].price - (product.product_variations[0].price / 100)* promoAmount).toFixed(2)}</span>
        <span className="text-xs text-neutral-600">{product.description}</span>
        
      </div>
      <div className="flex flex-col items-center justify-center flex-shrink-0 ">
        <img className="object-cover w-24 h-24 rounded-lg aspect-square" src={product.file_path} alt="" />
        <div className="flex items-center mt-4 w-fit">
          {itemInCart ? (
            itemQuantity > 1 ? (
              <>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-lg bg-prim_dark"
                  onClick={() => handleUpdateQuantity(itemQuantity - 1)}
                >
                  <FaMinus style={{ color: "white", fontSize: "12px" }} />
                </button>
                <span className="mx-2 text-lg font-semibold">
                  {itemQuantity}
                </span>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-lg bg-prim_red"
                  onClick={() => handleUpdateQuantity(itemQuantity + 1)}
                >
                  <FaPlus style={{ color: "white", fontSize: "12px" }} />
                </button>
              </>
            ) : (
              <>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-lg bg-prim_dark"
                  onClick={() => handleRemoveItem()}
                >
                  <FaTrash style={{ color: "white", fontSize: "12px" }} />
                </button>
                <span className="mx-2 text-lg font-semibold">
                  {itemQuantity}
                </span>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-lg bg-prim_red"
                  onClick={() => handleUpdateQuantity(itemQuantity + 1)}
                >
                  <FaPlus style={{ color: "white", fontSize: "12px" }} />
                </button>
              </>
            )
          ) : (
            <button
              style={{
                background: `radial-gradient(circle at top left, #F1656A, #EE3A43)`,
              }}
              className="px-4 py-2 text-sm font-bold text-white rounded-lg "
              onClick={handleAddItem}
            >
             加入購物車
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default HkProductCard
