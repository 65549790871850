import React from 'react';

const ProgressBar = ({ currentStep, totalSteps }) => {
  const progressPercentage = (currentStep / totalSteps) * 100;

  return (
    <div className="w-full h-2 bg-gray-200 rounded-full">
      <div
        style={{ width: `${progressPercentage}%` }}
        className="h-full transition-all duration-300 ease-in-out bg-blue-500 rounded-full"
      ></div>
    </div>
  );
};

export default ProgressBar;
