import React, { useContext } from "react"
import CountdownTimer from "./CountDownTimer"
import { PopupContext } from "../../App"
import { usePromo } from "../Initializer"
import { sendMessageToTelegram } from "../../telegramBot"
import trackUserAction from "../../track_action"

const ThankYou = () => {
  const { promo, promoAmount } = usePromo()
  const { set_show_popup, set_popup_height, setActiveComponent } =
    useContext(PopupContext)
  const handle_close = () => {
    trackUserAction("User Ended Quiz", true)
    trackUserAction(`Promocode: ${promo} / ${promoAmount}%`, false)
    set_popup_height(0)
    set_show_popup(false)

  }

  const initialTimeInSeconds = 5 * 3600 + 14 * 60 + 36

  return (
    <div
      data-aos="fade"
      className="relative flex flex-col items-center justify-center w-full mt-4 "
    >
      <div className="absolute top-0 z-40 w-full h-[14rem]">
        <img
          src="/124de3d1b5e12f1d8fcec1685e634361.gif"
          className="w-full h-full opacity-50"
          alt=""
        />
      </div>
      <div className="z-50 w-full ">
        <CountdownTimer initialTime={initialTimeInSeconds} />
      </div>

      <div className="z-50 flex flex-col items-center w-full px-4 mb-8 text-center">
        <div className="flex w-full">
          
        <div style={{ border: '3px dashed #D6D6D6' }}  className="z-50 flex flex-col items-center justify-center w-1/2 py-3 text-black bg-white rounded">
          <span className="text-sm font-semibold text-gray-500">
          你的優惠碼
          </span>
          <span className="text-xl font-bold text-gray-900 uppercase">
            {promo}
          </span>
        </div>
        <div className="flex flex-col items-center justify-center w-1/2">
        <span className="text-2xl font-extrabold text-prim_red">優惠 {promoAmount}%</span>
        <span className="text-sm font-bold text-gray-900">從全單</span>
        </div>
        </div>
        <div className="w-full p-1 mt-4 rounded bg-gray-50">
          <span className="w-full mt-3 text-base text-gray-500 ">
          在時間耗盡之前使用，否則將會過期。
          </span>
        </div>
      </div>
      <div className="z-50 flex items-center justify-center w-full px-4">
        <button
          onClick={handle_close}
          className="w-full p-3 mt-6 text-lg font-bold text-white shadow-md bg-prim_red rounded-xl"
        >
          兌換優惠券
        </button>
      </div>
    </div>
  )
}

export default ThankYou
