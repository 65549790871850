import React, { createContext, useContext, useState, useEffect } from 'react';

import ReactPixel from 'react-facebook-pixel';
import useHistoryParams from './useHistoryParam';
import { PopupContext } from '../App';

const PromoContext = createContext();

export const usePromo = () => useContext(PromoContext);

export const PromoProvider = ({ children }) => {
  const { params, updateParams } = useHistoryParams();
  const [promo, setPromo] = useState('NEW75');
  const [promoAmount, setPromoAmount] = useState(75);
  const {set_currency} = useContext(PopupContext)

  useEffect(() => {
    const pixelParam = params.get('pixelId');
    const promoParam = params.get('promo');
    const promoAmountParam = params.get('promo_ammount');
    const currency = params.get('currency');

    if (pixelParam) {
      ReactPixel.init(pixelParam);
      console.log('Facebook Pixel initialized with:', pixelParam);
    }

    if (promoParam) {
      setPromo(promoParam);
      localStorage.setItem('promocode', promoParam);
    }
    if (currency) {
      set_currency(currency);
      localStorage.setItem('promocode', promoParam);
    }

    if (promoAmountParam) {
      const amount = parseInt(promoAmountParam, 10);
      if (!isNaN(amount) && amount >= 0 && amount <= 100) {
        setPromoAmount(amount);
        localStorage.setItem('promo_amount', amount);
      } else {
        console.warn('Invalid promo amount:', promoAmountParam);
      }
    }

    // Remove params from URL if they exist
    if (pixelParam || promoParam || promoAmountParam) {
      updateParams({ pixelId: null, promo: null, promo_ammount: null, currency: null });
    }
  }, [params, updateParams]);

  return (
    <PromoContext.Provider value={{ promo, promoAmount }}>
      {children}
    </PromoContext.Provider>
  );
};
