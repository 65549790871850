import React, { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useSpring, animated } from '@react-spring/web';
import ProgressBar from './ProgressBar'; // Import the ProgressBar component


const QuestionPage = ({ question, options, nextPage, pageIndex, saveAnswer, img }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const totalSteps = 5;
  const [currentStep, setCurrentStep] = useState(1); // Track the current question/page number

  
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (currentStep < totalSteps) {
        setCurrentStep(currentStep + 1);
      }
    nextPage()
  };

  const handleNext = () => {
    if (selectedOption) {
      saveAnswer(pageIndex, selectedOption);
      nextPage();
    } else {
      alert('Please select an option before proceeding.');
    }
  };

  const springProps = useSpring({
    backgroundColor: selectedOption ? 'rgb(59, 130, 246)' : 'rgb(229, 231, 235)', // Tailwind colors (blue-500 and gray-200)
    transform: selectedOption ? 'scale(1.1)' : 'scale(1)',
    config: { tension: 210, friction: 20 },
  });

  return (
    <div data-aos="fade" className="flex flex-col items-center w-full p-4">
      <div className="w-32 h-32 mb-2 overflow-hidden rounded-full">
        <img src={`/ui/${img}`} className='w-full h-full' alt="" />
      </div>
      <p className="mb-8 text-lg font-semibold text-black">{question}</p>
      <div className="w-full mb-8">
      {options.map((option, index) => (
        <animated.label
          key={index}
          className={`flex items-center p-3 mb-4 border border-gray-100 rounded-lg cursor-pointer transition-all duration-300 ease-in-out ${
            selectedOption === option ? 'bg-prim_red shadow-md' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            name={`question-${pageIndex}`}
            value={option}
            checked={selectedOption === option}
            onChange={handleOptionChange}
            className="hidden peer"
          />
          <div className="w-4 h-4 mr-2 transition-colors duration-200 ease-in-out bg-gray-100 border border-gray-300 rounded peer-checked:bg-white peer-checked:border-white"></div>
          <span className="text-base text-gray-700 peer-checked:text-white">{option}</span>
        </animated.label>
      ))}
      </div>
      {/* <div className="flex justify-end w-full h-10"> */}

     
      {/* <button
        onClick={handleNext}
        className="flex items-center justify-center h-full mr-2 text-base text-white shadow-md bg-gray-50 aspect-square rounded-xl"
      >
        <FaArrowLeft color='#c2c2c2' />
      </button>
      <button
        onClick={handleNext}
        className="h-full text-base text-white shadow-md w-28 bg-prim_red rounded-xl"
      >
        Next
      </button> */}
      {/* </div> */}
    </div>
  );
};

export default QuestionPage;
