


const AuthLoading = () => {
    return(
        <div className="flex items-center justify-center w-full h-full">
            <img className="w-64 h-64" src="/LOADER_v13.f4840d49.gif" alt="" />
        </div>
    )
}

export default AuthLoading