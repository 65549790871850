import { useContext, useEffect, useState } from "react"
import CustomDropdown from "./CustomDropDown"
import { useCart } from "react-use-cart"
import { FaTrash } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"
import { FaMinus } from "react-icons/fa"
import "aos/dist/aos.css"
import AOS from "aos"
import { usePromo } from "./Initializer"
import { sendMessageToTelegram } from "../telegramBot"
import trackUserAction from "../track_action"
import { PopupContext } from "../App"
AOS.init({
  delay: 100, // Default delay in milliseconds for all elements
  offset: 100,
})

const PizzaCard = ({ product, set_show_loading }) => {
  const { currency, setCurrency } = useContext(PopupContext)

  const [priceObj, set_priceObj] = useState({ price: 1, currency: "AED" })
  const [editedPrice, setEditedPrice] = useState(priceObj.price)
  const [sizePrice, setSizePrice] = useState(priceObj.price)

  // useEffect(() => {
  //   set_priceObj(
  //     product.price.find((priceItem) => priceItem.currency === currency)
  //   )
  //   setEditedPrice(priceObj.price)
  //   setSizePrice(priceObj.price)
  //   console.log(priceObj)
  // }, [currency])
  console.log(product)
  console.log(priceObj)

  const [multiplier, setMultiplier] = useState(
    Math.floor(priceObj.price * 0.043)
  )
  const { promo, promoAmount } = usePromo()
  const {
    set_popup_product,
    setActiveComponent,
    set_show_popup,
    set_popup_height,
  } = useContext(PopupContext)
  const [crustOptions, setCrustOptions] = useState([
    {
      value: "crust1",
      label: "Pan",
      price: sizePrice,
      icon: "/crust/crust_pan.png",
    },
    {
      value: "crust2",
      label: "Thin N Crispy",
      price: sizePrice,
      icon: "/crust/crust_thin_n_crispy.png",
    },
    {
      value: "crust3",
      label: "Handcrafted Sourdough",
      price: sizePrice + multiplier,
      icon: "/crust/crust_handcrafted_sourdough.png",
    },
    {
      value: "crust4",
      label: "Stuffed Crust",
      price: sizePrice + multiplier * 2,
      icon: "/crust/crust_stuffed_crust.png",
    },
  ])
  const [sizeOptions, setSizeOptions] = useState([
    {
      value: "option1",
      label: "Small",
      price: priceObj.price - Math.floor((priceObj.price / 100) * 35),
      icon: "/size_small.png",
    },
    {
      value: "option2",
      label: "Medium",
      price: priceObj.price,
      icon: "/size_medium.png",
    },
    {
      value: "option3",
      label: "Large",
      price: priceObj.price + Math.floor((priceObj.price / 100) * 14),
      icon: "/size_large.png",
    },
  ])

  useEffect(() => {
    setSizePrice(priceObj.price)
    setEditedPrice(priceObj.price)
  }, [priceObj])

  const updateCrustOptions = () => {
    setCrustOptions([
      {
        value: "crust1",
        label: "Pan",
        price: sizePrice,
        icon: "/crust/crust_pan.png",
      },
      {
        value: "crust2",
        label: "Thin N Crispy",
        price: sizePrice,
        icon: "/crust/crust_thin_n_crispy.png",
      },
      {
        value: "crust3",
        label: "Handcrafted Sourdough",
        price: sizePrice + multiplier,
        icon: "/crust/crust_handcrafted_sourdough.png",
      },
      {
        value: "crust4",
        label: "Stuffed Crust",
        price: sizePrice + multiplier * 2,
        icon: "/crust/crust_stuffed_crust.png",
      },
    ])
  }

  // Update crust options when sizePrice or multiplier changes
  useEffect(() => {
    updateCrustOptions()
  }, [sizePrice, multiplier, currency])

  const updateSizeOptions = () => {
    setSizeOptions([
      {
        value: "option1",
        label: "Small",
        price: priceObj.price - Math.floor((priceObj.price / 100) * 35),
        icon: "/size_small.png",
      },
      {
        value: "option2",
        label: "Medium",
        price: priceObj.price,
        icon: "/size_medium.png",
      },
      {
        value: "option3",
        label: "Large",
        price: priceObj.price + Math.floor((priceObj.price / 100) * 14),
        icon: "/size_large.png",
      },
    ])
  }

  // Update crust options when sizePrice or multiplier changes
  useEffect(() => {
    updateSizeOptions()
  }, [sizePrice, multiplier, currency])

  useEffect(() => {
    const priceObjEf = product.price.find(
      (priceItem) => priceItem.currency === currency
    )
    setEditedPrice(priceObjEf.price)
  }, [currency])

  const {
    addItem,
    removeItem,
    updateItemQuantity,
    items,
    isEmpty,
    totalItems,
    cartTotal,
    emptyCart,
  } = useCart()

  const [selectedOption, setSelectedOption] = useState(sizeOptions[1]) // Default to Medium
  const [selectedCrustOption, setSelectedCrustOption] = useState(
    crustOptions[1]
  ) // Default to Thin N Crispy

  useEffect(() => {
    const option = sizeOptions.find((opt) => opt.value === selectedOption.value)
    if (option) {
      setSizePrice(option.price)
      setMultiplier(
        option.value === "option3"
          ? Math.floor(priceObj.price * 0.065)
          : option.value === "option1"
          ? Math.floor(priceObj.price * 0.021)
          : Math.floor(priceObj.price * 0.043)
      )
    }
  }, [selectedOption])

  useEffect(() => {
    const crustOption = crustOptions.find(
      (opt) => opt.value === selectedCrustOption.value
    )
    if (crustOption) {
      setEditedPrice(crustOption.price)
    }
  }, [selectedCrustOption, sizePrice, multiplier])

  const handleChangeSize = (selected) => {
    setSelectedOption(selected)
  }

  const handleChangeCrust = (selected) => {
    setSelectedCrustOption(selected)
  }

  const handleAddItem = () => {
    trackUserAction("User Added Product To Cart", true)
    trackUserAction(`Product: ${product.name}`, false)
    const item = {
      id: product.id,
      name: product.name,
      price: editedPrice,
      img: product.src,
      quantity: 1,
      description: product.description,
      toppings: "none",
      size: selectedOption,
      crust: selectedCrustOption,
    }
    set_show_loading(true)
    setTimeout(() => set_show_loading(false), 1000) // Hide overlay after 2 seconds
    setTimeout(() => {
      addItem(item)
    }, 500)

    console.log(
      `items:${JSON.stringify(
        items
      )}, IsEmpty:${isEmpty}, TotalItems: ${totalItems}, cartTotal:${cartTotal}, emptyCart:${emptyCart}`
    )
  }

  const handle_customize = () => {
    set_popup_product({
      id: product.id,
      name: product.name,
      price: editedPrice,
      img: product.src,
      quantity: 1,
      description: product.description,
      toppings: "none",
      size: selectedOption,
      crust: selectedCrustOption,
      originalPrice: priceObj.price,
      crustOptions: crustOptions,
      sizeOptions: sizeOptions,
    })
    setActiveComponent("prod_custom")
    set_show_popup(true)
    set_popup_height(35)
  }

  const isInCart = (productId) => {
    return items.some((item) => item.id === productId)
  }

  const itemInCart = items.find((cartItem) => cartItem.id === product.id)
  const itemQuantity = itemInCart ? itemInCart.quantity : 0

  const handleRemoveItem = () => {
    removeItem(product.id)
    set_show_loading(true)
    setTimeout(() => set_show_loading(false), 500) // Hide overlay after 2 seconds
  }

  const handleUpdateQuantity = (newQuantity) => {
    updateItemQuantity(product.id, newQuantity)
  }
  return (
    <div className="flex flex-col w-full mb-4 bg-white border shadow border-slate-100 rounded-xl">
      <div className="flex flex-col w-full p-2">
        <span className="font-semibold">{product.name}</span>
        <span className="text-xs text-gray-500 ">{product.description}</span>
      </div>
      <div className="flex justify-between w-full"></div>
      <div className="relative w-full overflow-hidden rounded-xl">
        <img
          className="object-contain w-full h-44 "
          src={`/images/${product.src}`}
          alt=""
        />
        <div className="absolute bottom-0 left-0 right-0 w-full h-20 opacity-30 bg-gradient-to-b from-transparent to-black"></div>
        <div className="absolute top-0 bottom-0 left-0 right-0 z-50">
          <div class="absolute flex  right-0 bottom-0  w-[11rem]   border-l-[15px] border-l-transparent border-b-[40px] border-b-prim_red"></div>

          <div className="absolute flex items-start bottom-2 right-2">
            <span className="mr-2 text-sm font-bold leading-none text-white line-through opacity-85">
              {priceObj.price}.00
            </span>
            <span className="text-xl font-bold leading-none text-white ">
              {priceObj.price - Math.floor((priceObj.price / 100) * promoAmount)}.00{" "}
              {priceObj.currency}
            </span>
          </div>
          {/* <div className="absolute flex flex-col items-end right-3 bottom-2">
            <span className="font-bold text-white line-through opacity-75 text">
              {editedPrice}.00 {product.currency}
            </span>
            <span className="text-xl font-bold text-white border-b-2 border-prim_red ">
              {editedPrice - Math.floor((editedPrice / 100) * promoAmount)}.00{" "}
              {product.currency}
            </span>
          </div> */}
          <div
            onClick={handle_customize}
            className="absolute flex items-center p-1 bg-white rounded bg-opacity-65 left-2 bottom-2"
          >
            <span className="mr-1 text-sm font-semibold ">Cutomize</span>
            <div className="flex items-center justify-center w-5 h-5 rounded bg-prim_red">
              <span className="font-bold text-white ">+</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between p-2">
        <div className="flex">
          <div className="flex flex-col">
            <span className="mr-2 text-xs text-gray-500">Choose Size</span>
            <CustomDropdown
              options={sizeOptions}
              onChange={handleChangeSize}
              selectedOption={selectedOption}
              currency={priceObj.currency}
            />
          </div>
          <div className="flex flex-col">
            <span className="text-xs text-gray-500">Choose Crust</span>
            <CustomDropdown
              options={crustOptions}
              onChange={handleChangeCrust}
              selectedOption={selectedCrustOption}
              currency={priceObj.currency}
            />
          </div>
        </div>

        <div className="flex items-center w-fit ">
          {itemInCart ? (
            itemQuantity > 1 ? (
              <>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-lg bg-prim_dark"
                  onClick={() => handleUpdateQuantity(itemQuantity - 1)}
                >
                  <FaMinus style={{ color: "white", fontSize: "12px" }} />
                </button>
                <span className="mx-2 text-lg font-semibold">
                  {itemQuantity}
                </span>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-lg bg-prim_red"
                  onClick={() => handleUpdateQuantity(itemQuantity + 1)}
                >
                  <FaPlus style={{ color: "white", fontSize: "12px" }} />
                </button>
              </>
            ) : (
              <>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-lg bg-prim_dark"
                  onClick={() => handleRemoveItem()}
                >
                  <FaTrash style={{ color: "white", fontSize: "12px" }} />
                </button>
                <span className="mx-2 text-lg font-semibold">
                  {itemQuantity}
                </span>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-lg bg-prim_red"
                  onClick={() => handleUpdateQuantity(itemQuantity + 1)}
                >
                  <FaPlus style={{ color: "white", fontSize: "12px" }} />
                </button>
              </>
            )
          ) : (
            <button
              style={{
                background: `radial-gradient(circle at top left, #F1656A, #EE3A43)`,
              }}
              className="flex items-center px-6 py-[0.7rem] text-sm font-bold leading-none text-white rounded-lg"
              onClick={handleAddItem}
            >
              <div className="flex items-center justify-center w-4 h-4 mr-1 bg-white rounded">
                <span className="text-xl font-bold leading-none text-prim_red">
                  +
                </span>
              </div>
              ADD
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default PizzaCard
