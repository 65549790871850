import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.css"
import posthog from "posthog-js"
import { Route, Router, Routes } from "react-router-dom"
import UserStats from "./UserStats"


const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
