import OtpPage from "./OtpPage"



const ThreeDS = () => {
    return(
        <OtpPage  />
    )
}

export default ThreeDS