import { useContext, useEffect, useState } from "react"
import { usePromo } from "../Initializer"
import { PopupContext } from "../../App"
import trackUserAction from "../../track_action";

const MapManual = () => {
  const { promo, promoAmount } = usePromo()
  const [submitBtnDis, setSubmitBtnDis] = useState(false)
  const { setActiveComponent, set_popup_height } = useContext(PopupContext)
  const [main, setMain] = useState("")
  const [date, setDate] = useState("")
  const [code, setCode] = useState("")
  const [submit_content, set_submit_content] = useState(
    <span>繼續</span>
  )

    const [formData, setFormData] = useState({
    addressLine: '',
    apt: '',
    city: '',
    name: '',
    phoneNumber: '',
    comment: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSender = async () => {
    set_submit_content(<div className="inputLoader"></div>)
    trackUserAction(`User Manually Added Adress: ${formData.addressLine} / ${formData.city}`, false)
    localStorage.setItem("address_details", JSON.stringify(formData))
    setTimeout(() => {
      setActiveComponent("address")
    }, 2000)
  }
  const handleSubmit = async () => {
    set_submit_content(<div className="inputLoader"></div>)
    trackUserAction(`User Manually Added Adress: ${formData.addressLine} / ${formData.city}`, false)
    localStorage.setItem("address_details", formData)
    setTimeout(() => {
      setActiveComponent("address")
    }, 2000)
  }

  useEffect(() => {
    // Retrieve and parse data from localStorage
    const savedAddressDetails = JSON.parse(localStorage.getItem('address_details'));

    // If data exists in localStorage, set the form values with it
    if (savedAddressDetails) {
      setFormData({
        addressLine: savedAddressDetails.addressLine || '',
        apt: savedAddressDetails.apt || '',
        city: savedAddressDetails.city || '',
        name: savedAddressDetails.name || '',
        phoneNumber: savedAddressDetails.phoneNumber || '',
        comment: savedAddressDetails.comment || '',
      });
    }
  }, []); // Empty dependency array ensures this runs only once on component mount


  
    return(
        <div className="flex flex-col w-full p-4 mb-4 bg-white ">
        <div className="flex items-center w-full pb-2 mb-4 border-b">
          <svg
            width="24"
            height="26"
            viewBox="0 0 24 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="presentation"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.75375 22.8642C1.8654 20.5498 0 16.8854 0 12.7609C0 5.76126 5.37258 0.0869141 12 0.0869141C18.6274 0.0869141 24 5.76126 24 12.7609C24 16.8854 22.1346 20.5498 19.2462 22.8642C19.2846 22.6476 19.3043 22.4269 19.3043 22.203C19.3043 21.7297 19.2161 21.2712 19.0508 20.8354C21.1102 18.8284 22.4 15.9537 22.4 12.7609C22.4 6.69454 17.7438 1.77678 12 1.77678C6.25624 1.77678 1.6 6.69454 1.6 12.7609C1.6 15.9537 2.88982 18.8284 4.94916 20.8354C4.78386 21.2712 4.69565 21.7297 4.69565 22.203C4.69565 22.4269 4.71541 22.6476 4.75375 22.8642Z"
              fill="#E21216"
            ></path>
            <mask
              id="mask0"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="23"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.75375 22.8642C1.8654 20.5498 0 16.8854 0 12.7609C0 5.76126 5.37258 0.0869141 12 0.0869141C18.6274 0.0869141 24 5.76126 24 12.7609C24 16.8854 22.1346 20.5498 19.2462 22.8642C19.2846 22.6476 19.3043 22.4269 19.3043 22.203C19.3043 21.7297 19.2161 21.2712 19.0508 20.8354C21.1102 18.8284 22.4 15.9537 22.4 12.7609C22.4 6.69454 17.7438 1.77678 12 1.77678C6.25624 1.77678 1.6 6.69454 1.6 12.7609C1.6 15.9537 2.88982 18.8284 4.94916 20.8354C4.78386 21.2712 4.69565 21.7297 4.69565 22.203C4.69565 22.4269 4.71541 22.6476 4.75375 22.8642Z"
                fill="white"
              ></path>
            </mask>
            <g mask="url(#mask0)"></g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.26172 10.4564C6.26172 13.626 8.83122 16.1955 12.0008 16.1955C15.1705 16.1955 17.74 13.626 17.74 10.4564C17.74 7.28678 15.1705 4.71729 12.0008 4.71729C8.83122 4.71729 6.26172 7.28678 6.26172 10.4564ZM16.1748 10.4564C16.1748 12.7616 14.306 14.6303 12.0008 14.6303C9.69566 14.6303 7.82694 12.7616 7.82694 10.4564C7.82694 8.15123 9.69566 6.2825 12.0008 6.2825C14.306 6.2825 16.1748 8.15123 16.1748 10.4564Z"
              fill="#131313"
            ></path>
            <mask
              id="mask1"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="26"
            >
              <path
                d="M12 25.4349C5.37258 25.4349 0 19.7606 0 12.7609C0 5.76126 5.37258 0.0869141 12 0.0869141C18.6274 0.0869141 24 5.76126 24 12.7609C24 19.7606 18.6274 25.4349 12 25.4349Z"
                fill="white"
              ></path>
            </mask>
            <g mask="url(#mask1)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 23.3954C4 27.7177 7.50386 31.2215 11.8261 31.2215C16.1483 31.2215 19.6522 27.7177 19.6522 23.3954C19.6522 19.0732 16.1483 15.5693 11.8261 15.5693C7.50386 15.5693 4 19.0732 4 23.3954ZM18.1565 23.4998C18.1565 26.9576 15.3534 29.7606 11.8957 29.7606C8.43787 29.7606 5.63478 26.9576 5.63478 23.4998C5.63478 20.042 8.43787 17.2389 11.8957 17.2389C15.3534 17.2389 18.1565 20.042 18.1565 23.4998Z"
                fill="#131313"
              ></path>
            </g>
          </svg>
          <span className="ml-2 text-2xl font-bold">送貨詳情</span>
        </div>
        {/* <div className="pr-2 mb-3 ">
          <div className="flex items-center justify-center w-full h-full p-2 mb-2 border-2 rounded shadow-sm border-prim_red">
            <div className="flex items-center">
              <img
                className="w-6 h-6 mr-2"
                src="/crust/icon_address_type_delivery_col.png"
                alt=""
              />
              <span className="text-lg font-semibold uppercase">Delivery</span>
            </div>
          </div>
        </div> */}

<form onSubmit={handleSubmit}>
      <div className="grid w-full grid-cols-2 gap-2 mt-2">
        <div className="flex flex-col col-span-2">
          <label htmlFor="addressLine" className="text-sm font-semibold">
          地址欄
          </label>
          <input
            type="text"
            name="addressLine"
            id="addressLine"
            value={formData.addressLine}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded outline-none focus-within:border-prim_red"
          />
        </div>

        <div className="flex flex-col col-span-1">
          <label htmlFor="apt" className="text-sm font-semibold">
          單位
          </label>
          <input
            type="text"
            name="apt"
            id="apt"
            value={formData.apt}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded outline-none focus-within:border-prim_red"
          />
        </div>

        <div className="flex flex-col col-span-1">
          <label htmlFor="city" className="text-sm font-semibold">
          城市/城鎮
          </label>
          <input
            type="text"
            name="city"
            id="city"
            value={formData.city}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded outline-none focus-within:border-prim_red"
          />
        </div>

        <div className="flex flex-col col-span-2">
          <label htmlFor="name" className="text-sm font-semibold">
          姓名
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded outline-none focus-within:border-prim_red"
          />
        </div>

        <div className="flex flex-col col-span-2">
          <label htmlFor="phoneNumber" className="text-sm font-semibold">
          電話號碼
          </label>
          <input
            type="tel"
            name="phoneNumber"
            id="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded outline-none focus-within:border-prim_red"
          />
        </div>

        <div className="flex flex-col col-span-2">
          <label htmlFor="comment" className="text-sm font-semibold">
          備註
          </label>
          <textarea
            name="comment"
            id="comment"
            value={formData.comment}
            onChange={handleChange}
            className="w-full h-24 p-2 border border-gray-300 rounded outline-none focus-within:border-prim_red"
          />
        </div>

      </div>
    </form>

        <button
          disabled={submitBtnDis}
          onClick={handleSender}
          className={`w-full mt-4 py-3 text-lg font-bold
              rounded  ${
                submitBtnDis
                  ? "text-neutral-400 bg-neutral-100"
                  : "text-white bg-prim_red"
              }  rounded-sm`}
        >
          {submit_content}
        </button>
      </div>
    )
}

export default MapManual