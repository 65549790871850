import React, { useState, useEffect, useContext } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { motion, AnimatePresence } from 'framer-motion';
import { PopupContext } from '../../App';

const images = [
  '/loader/1.png',
  '/loader/2.png',
  '/loader/3.png'
];

const stepsText = [
  "您的訂單已提交。我們正在處理中...",
  "我們的團隊正在審核您的訂單詳情以確保一切正確...",
  "您的訂單已由餐廳確認..."
]

const AnimatedProgressBar = () => {
  const { setActiveComponent, set_popup_height } = useContext(PopupContext)
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 33; // For 3 steps
        if (newProgress >= 100) {
          return 100; // Stop at 100% progress
        }
        return newProgress;
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress >= 100) return;
    const newIndex = Math.floor(progress / 33); // Update step and image every 33% progress
    setCurrentStep(newIndex);
  }, [progress]);
  useEffect(()=>{
    if(currentStep ===3){
      setActiveComponent('card')
    }
  },[currentStep])

  return (
    <div className="flex flex-col items-center justify-center h-screen space-y-4">
      <div style={{ width: 150, height: 150, position: 'relative' }}>
        {/* Circular Progress Bar */}
        <CircularProgressbar
          value={progress}
          styles={buildStyles({
            pathColor: '#EE3A43',
            trailColor: '#e0e0e0',
            strokeLinecap: 'round',
            pathTransitionDuration: 0.5,
            strokeWidth: 1
          })}
        />

        {/* Image inside progress bar */}
        <motion.div
          key={currentStep} // Trigger animation on change
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          style={{
            position: 'absolute',
          }}
          className='top-0 bottom-0 left-0 right-0 flex items-center justify-center overflow-hidden rounded-full'
        >

          <img
            src={images[currentStep]}
            alt={`Step ${currentStep + 1}`}
            className="rounded-full"
          />
        </motion.div>
      </div>

      {/* Step Text with Fade In-Out Animation */}
      <div className='flex justify-center w-full' style={{ height: '40px' }}>
        <AnimatePresence mode='wait'>
          <motion.div
            key={currentStep} // Key for smooth transition between steps
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.5 }}
            className="w-2/3 font-medium text-center text-gray-700 tex-tsm "
          >
            {stepsText[currentStep]}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AnimatedProgressBar;
