import { FaPizzaSlice } from "react-icons/fa"
import ProductCard from "./components/ProductCard"
import { useContext, useState } from "react"
import { PopupContext } from "./App"
import PizzaCard from "./components/PizzaCard"
import HkProductCard from "./components/HkProductCard"

const ProductSection = ({ category }) => {
  // function retrieve_products(array) {
  //   return array.data.products
  // }

  const { set_show_loading } = useContext(PopupContext)
  
  return (
    <section
      id={`${category.id}`}
      className="flex flex-col w-full h-full p-2"
    >
      {/* <div className="flex items-center w-full p-3 mb-2 bg-white border shadow border-slate-100 rounded-xl">
        <FaPizzaSlice
          size={22}
          className="mr-2"
          color="#EE3A43"
        />
        <span className="text-2xl font-bold">Pizza</span>
      </div> */}
      <div class="flex items-center justify-between my-4 w-full">
        <div class="flex items-center w-full">
          <span class="w-4 h-4 bg-red-500 rounded-full  flex-shrink-0"></span>
          <div class="w-full border-dashed border-t border-gray-300 mx-2"></div>
        </div>

        <span class="text-center text-xl flex-shrink-0 font-semibold">{category.name}</span>

        <div class="flex items-center w-full">
          <div class="w-full border-dashed border-t border-gray-300 mx-2"></div>
          <span class="w-4 h-4 bg-red-500 rounded-full  flex-shrink-0"></span>
        </div>
      </div>

      {/* {category.products.map((product) => (
    <ProductCard
      key={product.id} // Always add a unique key
      product={product}
      set_show_loading={set_show_loading}
    />
  ))} */}
      {category.products.map((product) => (
    <HkProductCard
      key={product.id} // Always add a unique key
      product={product}
      set_show_loading={set_show_loading}
    />
  ))}

        
    </section>
  )
}

export default ProductSection
