import React, { useContext, useState } from "react"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import Welcome from "./quiz/Welcome"
import QuestionPage from "./quiz/QuestionPage"
import ThankYou from "./quiz/ThankYou"
import ProgressBar from "react-bootstrap/ProgressBar"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { PopupContext } from "../App"
import { sendMessageToTelegram } from "../telegramBot"
AOS.init({
  delay: 100, // Default delay in milliseconds for all elements
  offset: 100,
});
// const questions = [
//   { text: "What is your favorite color?", options: ["Red", "Blue", "Green", "Yellow"] },
//   { text: "What is your favorite food?", options: ["Pizza", "Sushi", "Burgers", "Pasta"] },
//   { text: "What is your hobby?", options: ["Reading", "Gaming", "Cooking", "Traveling"] },
//   { text: "What is your dream job?", options: ["Engineer", "Artist", "Doctor", "Entrepreneur"] },
//   { text: "What is your favorite movie?", options: ["Inception", "Titanic", "Avatar", "Matrix"] },
// ];

const questions = [
  {
    "text": "你通常在 Pizza Hut 點什麼大小的比薩？",
    "options": ["細", "中", "大"],
    "img": "Designer58.jpeg"
  },
  {
    "text": "你喜歡哪種比薩的餅皮？",
    "options": ["薄餅", "深盤餅", "餡料餅", "手工擲餅"],
    "img": "Designer70.jpeg"
  },
  {
    "text": "你多久從 Pizza Hut 點一次餐？",
    "options": ["每週一次", "每月幾次", "每月一次", "很少"],
    "img": "Designer57.jpeg"
  },
  {
    "text": "你會如何評價最近一次在 Pizza Hut 的體驗？",
    "options": ["優秀", "良好", "一般", "差"],
    "img": "Designer59.jpeg"
  },
  {
    "text": "你通常會在比薩上加什麼額外配料？",
    "options": [
      "額外芝士",
      "意大利香腸",
      "蘑菇",
      "沒有額外配料"
    ],
    "img": "Designer73.jpeg"
  }
]

const App = () => {
  const [page, setPage] = useState(0)
  const [answers, setAnswers] = useState([])

  const nextPage = () => {

    setPage((prevPage) => prevPage + 1)
  }

  const saveAnswer = (index, answer) => {
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers]
      newAnswers[index] = answer
      return newAnswers
    })
  }

  const progress = (page / 6) * 100
  return (
    <div className="w-full">
      <SwitchTransition>
        <CSSTransition
          key={page}
          timeout={300}
          classNames="fade"
        >
          <div>
            {page === 0 && <Welcome startQuiz={nextPage} />}
            {page > 0 && page <= 5 && (
              <>
                <div className="w-full px-4 mt-4">
                  <ProgressBar
                    animated
                    variant="danger"
                    now={progress}
                    label={`${Math.floor(progress)}%`}
                  />
                </div>
                <QuestionPage
                  question={questions[page - 1].text}
                  options={questions[page - 1].options}
                  img={questions[page - 1].img}
                  nextPage={nextPage}
                  pageIndex={page - 1}
                  saveAnswer={saveAnswer}
                />
              </>
            )}
            {page === 6 && (
              <>
                <div className="w-full px-4 mt-4">
                  <ProgressBar
                    animated
                    variant="danger"
                    now={95}
                    label={`95%`}
                  />
                </div>
                <ThankYou/>
              </>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}

export default App
