import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-scroll';
import products from './data/hk.json'

const Navbar = ({ set_active_products }) => {
  const [isSticky, setIsSticky] = useState(false);
  const sections = ['Pizza', 'Pasta', 'Wingstreet', 'Starters',  'Extras',  'Desserts', 'Drinks'];
  console.log(products.data.menus.menu_categories)
  // Reference to the container that holds the links
  const containerRef = useRef(null);
  const sectionRefs = useRef({});

  // Function to handle scroll event
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 265) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  // Scroll to the active section
  const scrollToActiveSection = (section) => {
    const activeSection = sectionRefs.current[section];
    if (activeSection && containerRef.current) {
      // Scroll the container to the active section's offset
      containerRef.current.scrollTo({
        left: activeSection.offsetLeft - 150, // Adjust offset to align properly
        behavior: 'smooth',
      });
    }
  };

  // Use useEffect to listen to scroll event on component mount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`${
        isSticky ? 'fixed tcusz top-0 left-0 w-full shadow' : 'relative'
      } transition-all flex bg-white duration-300 pt-4 text-white`}
    >
      <div className="flex items-center pb-2 pl-4 mr-4">
        <div className="w-12 h-12 p-2 mr-2 overflow-hidden rounded-full bg-prim_red">
          <img className="w-full h-full" src={`/section/menu.svg`} alt="" />
        </div>

      </div>
      <div
        className="flex items-center pr-2 overflow-x-scroll"
        ref={containerRef} // Reference to the scrollable container
      >
        {products.data.menus[0].menu_categories.map((section) => (
          <Link
            key={section.id}
            to={section.id}
            smooth={true}
            duration={500}
            spy={true}
            activeClass="active"
            className="flex items-center flex-shrink-0 h-full pb-2 mx-4 cursor-pointer"
            onSetActive={() => scrollToActiveSection(section.id)} // Trigger scroll when section becomes active
            
          >

            <span ref={(el) => (sectionRefs.current[section.id] = el)} className="text-sm font-semibold text-gray-500">{section.name}</span>
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default Navbar;
