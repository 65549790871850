import React from 'react';
import { usePromo } from '../Initializer';
import trackUserAction from '../../track_action';
import { FaTag } from 'react-icons/fa';



const Welcome = ({ startQuiz }) => {
  const { promo, promoAmount } = usePromo();
  return (
    <div className="flex flex-col w-full overflow-hidden">
    <div className="w-full">
      <img
        src="/slDZcKnmSWcv.jpg"
        className="w-full"
        alt=""
      />
    </div>
    <div className="flex justify-center w-full">
    <div className="flex items-center w-full p-4 border-b">


    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3 3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V14.5C20.6193 14.5 19.5 13.3807 19.5 12C19.5 10.6193 20.6193 9.5 22 9.5V4C22 3.44772 21.5523 3 21 3H3ZM9.5 17.9978C8.86346 17.9978 8.29623 18.3995 8.085 19H4V5H8.085C8.29623 5.60047 8.86346 6.00223 9.5 6.00223C10.1365 6.00223 10.7038 5.60047 10.915 5H20V7.968C18.4677 8.72742 17.4982 10.2898 17.4982 12C17.4982 13.7102 18.4677 15.2726 20 16.032V19H10.915C10.7038 18.3995 10.1365 17.9978 9.5 17.9978ZM9.5 11C8.67157 11 8 10.3284 8 9.5C8 8.67157 8.67157 8 9.5 8C10.3284 8 11 8.67157 11 9.5C11 10.3284 10.3284 11 9.5 11ZM8 14.5C8 15.3284 8.67157 16 9.5 16C10.3284 16 11 15.3284 11 14.5C11 13.6716 10.3284 13 9.5 13C8.67157 13 8 13.6716 8 14.5Z" fill="black"/>
</svg> */}
        <FaTag />

        <span className="ml-2 text-2xl font-bold ">
        特別優惠
        </span>
      </div>
    </div>
    <div className="flex flex-col w-full p-4">
      <span className="text-2xl font-semibold text-left ">
      參加一個簡短的五題調查，即可獲得高達{" "}
        <span className="text-prim_red ">-{promoAmount}%</span> 優惠！
      </span>
      <span className="mt-2 text-sm text-gray-500">折扣只適用於特別餐單。優惠有效期至另行通知</span>
      <div className="flex items-center justify-center w-full">
          <button onClick={()=>{startQuiz(); trackUserAction('User Started Quiz', true)}} className="w-full p-3 mt-8 text-lg font-bold text-white shadow-md bg-prim_red rounded-xl">開始調查</button>
      </div>
    </div>
    <br></br>
  </div>
  );
};

export default Welcome;
