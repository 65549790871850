import { useContext, useEffect, useState } from "react"
import { PopupContext } from "../App"
import { useCart } from "react-use-cart"
import { usePromo } from "./Initializer"
import { FiTrash } from 'react-icons/fi'; // Feather Icons
import trackUserAction from "../track_action";

const CustomizeProduct = () => {
    const {set_popup_product, setActiveComponent, set_show_popup, set_popup_height, popup_product, set_show_loading, currency} = useContext(PopupContext)

  console.log(popup_product)
    const [editedPrice, setEditedPrice] = useState(popup_product.originalPrice)
    const [toppings_price, set_toppings_price] = useState(editedPrice)
    const [sizePrice, setSizePrice] = useState(popup_product.originalPrice)
    const [multiplier, setMultiplier] = useState(2)
    const { promo, promoAmount } = usePromo()

    const {
      addItem,
      removeItem,
      updateItemQuantity,
      items,
      isEmpty,
      totalItems,
      cartTotal,
      emptyCart,
    } = useCart()

    const sauceOptions = [
      {
        value: "sauce1",
        label: "Fiery Peri Peri",
        price: Math.floor(popup_product.originalPrice * 0.075),
        icon: "/sauce/step_820065.png",
      },
      {
        value: "sauce2",
        label: "Cool Ranch",
        price: Math.floor(popup_product.originalPrice * 0.075),
        icon: "/sauce/step_820032.png",
      },
      {
        value: "sauce3",
        label: "Bold BBQ",
        price: Math.floor(popup_product.originalPrice * 0.075),
        icon: "/sauce/step_820002.png",
      }
    ]

    const extrasOptions = [
      {
        "title": "Fresh Cut Onions",
        "src": "/extras/step_820063.png",
        "value": "extras1",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Mozzarella",
        "src": "/extras/step_820057.png",
        "value": "extras2",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Green Pepper",
        "src": "/extras/step_820045.png",
        "value": "extras3",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Chicken Fajita",
        "src": "/extras/step_820024.png",
        "value": "extras4",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Sliced Jalapenos-L",
        "src": "/extras/step_820132.png",
        "value": "extras5",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Roasted Turkey",
        "src": "/extras/step_820099.png",
        "value": "extras6",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Pineapples",
        "src": "/extras/step_820072.png",
        "value": "extras7",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Fresh Tomatoes",
        "src": "/extras/step_820096.png",
        "value": "extras8",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Mushrooms",
        "src": "/extras/step_820060.png",
        "value": "extras9",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Shrimps",
        "src": "/extras/step_820084.png",
        "value": "extras10",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Green Chilies",
        "src": "/extras/step_820051.png",
        "value": "extras11",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Grilled Chicken",
        "src": "/extras/step_820048.png",
        "value": "extras12",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Ground Beef",
        "src": "/extras/step_820009.png",
        "value": "extras13",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Chicken Pepperoni",
        "src": "/extras/step_820027.png",
        "value": "extras14",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Black Olives",
        "src": "/extras/step_820018.png",
        "value": "extras15",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      },
      {
        "title": "Pepperoni",
        "src": "/extras/step_820015.png",
        "value": "extras16",
        "price": Math.floor(popup_product.originalPrice * 0.12)
      }
    ]
    
  
    const [selectedOption, setSelectedOption] = useState(popup_product.sizeOptions[1]) // Default to Medium
    const [selectedCrustOption, setSelectedCrustOption] = useState(
      popup_product.crustOptions[1]
    ) // Default to Thin N Crispy
    const [selected_sauce, set_selected_sauce] = useState([])
    const [selected_extras, set_selected_extras] = useState([])
  
    useEffect(() => {
      const option = popup_product.sizeOptions.find((opt) => opt.value === selectedOption.value)
      console.log(option)
      if (option) {
        setSizePrice(option.price)
        setMultiplier(
          option.value === "option3" ? 3 : option.value === "option1" ? 1 : 2
        )
      }
    }, [selectedOption])
  
    useEffect(() => {
      const crustOption = popup_product.crustOptions.find(
        (opt) => opt.value === selectedCrustOption.value
      )
      if (crustOption) {
        setEditedPrice(crustOption.price)
        console.log(editedPrice)
      }
    }, [selectedCrustOption, sizePrice, multiplier])
  
    const handleChangeSize = (selected) => {
      setSelectedOption(selected)
      console.log(selected)
      console.log(multiplier)
      console.log(sizePrice)
    }
  
    const handleChangeCrust = (selected) => {
      setSelectedCrustOption(selected)
    }

    const sauce_selected = (option) => {
      return selected_sauce.find((cartItem) => cartItem.value === option.value)
    } 
    const extras_selected = (option) => {
      return selected_extras.find((cartItem) => cartItem.value === option.value)
    } 

    const toggle_sauce = (option) => {
      if (sauce_selected(option)) {
        // Remove sauce from state if it's already selected
        set_selected_sauce((prev) => prev.filter((sauce) => sauce.value !== option.value));
      } else {
        // Add sauce to state if not selected
        set_selected_sauce((prev) => [...prev, option]);
      }
    };
    const toggle_extras = (option) => {
      if (extras_selected(option)) {
        // Remove sauce from state if it's already selected
        set_selected_extras((prev) => prev.filter((sauce) => sauce.value !== option.value));
      } else {
        // Add sauce to state if not selected
        set_selected_extras((prev) => [...prev, option]);
      }
    };

    useEffect(()=>{
      const totalSaucePrice = selected_sauce.reduce((acc, curr) => acc + curr.price, 0);
      const totalExtrasPrice = selected_extras.reduce((acc, curr) => acc + curr.price, 0);
      set_toppings_price(editedPrice + totalSaucePrice + totalExtrasPrice)
    },[editedPrice,
      selected_sauce,
      selected_extras])


      const handleAddItem = () => {
        trackUserAction("User Added Product To Cart", true)
        trackUserAction(`Product: ${popup_product.name}`, false)
        const item = {
          id: popup_product.id,
          name: popup_product.name,
          price: toppings_price,
          img: popup_product.img,
          quantity: 1,
          description: popup_product.description,
          toppings: "none",
          size: selectedOption,
          crust: selectedCrustOption,
        }
        set_show_loading(true)
        set_popup_height(0)
        set_show_popup(false)
        setTimeout(() => set_show_loading(false), 2000) // Hide overlay after 2 seconds
        setTimeout(() => {
          addItem(item)
        }, 500)
    
        console.log(
          `items:${JSON.stringify(
            items
          )}, IsEmpty:${isEmpty}, TotalItems: ${totalItems}, cartTotal:${cartTotal}, emptyCart:${emptyCart}`
        )
      }
    return(
        <div className="relative w-full h-full ">
          <div className="fixed h-16 px-2 bg-red-500 rounded bottom-2 left-2 right-2 cusz">
          <div className="flex justify-between w-full h-full p-2">
            <div className="flex w-full">
              <div className="flex items-center justify-center flex-shrink-0 h-full pr-2 mr-1 border-r border-white aspect-square">
                <img className="flex-shrink-0 object-cover w-12 h-12 rounded " src={`/images/${popup_product.img}`} alt="" />
              </div>
              <div className="flex items-center justify-center w-full h-full border-white rounded">
                {/* <svg
                  stroke="white"
                  fill="white"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  class="CartButton_cartIcon__2kA9L"
                  height="2em"
                  width="2em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      fill="none"
                      d="M0 0h24v24H0z"
                    ></path>
                    <path d="M4 16V4H2V2h3a1 1 0 0 1 1 1v12h12.438l2-8H8V5h13.72a1 1 0 0 1 .97 1.243l-2.5 10a1 1 0 0 1-.97.757H5a1 1 0 0 1-1-1zm2 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm12 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"></path>
                  </g>
                </svg> */}
              <div className="flex flex-col items-center justify-center w-full h-full">
                <div className="flex flex-col w-full px-2 text-sm font-bold text-white">
                  <div className="flex items-center w-full">
                  {/* <div className="flex items-center justify-center w-4 h-4 mr-1 bg-white rounded-full text-prim_red"> */}
                    <span className="mr-1">1 Item</span>
                  {/* </div> */}

                  
                  </div>
                  <span className="">{toppings_price-Math.floor((toppings_price/100)*promoAmount)}.00 {currency}</span>
                </div>
                {/* <span className="text-xs text-white opacity-70">
                  Inclusive of taxes
                </span> */}
              </div>
              </div>
            </div>
            <div onClick={handleAddItem} className="flex items-center justify-between flex-shrink-0 h-full p-2 px-4 bg-white rounded">
              {/* <FaMapMarkerAlt color="red" /> */}
              <span  className="ml-1 font-bold text-prim_red">
                Add
              </span>
            </div>
          </div>
          </div>
        <div className="w-full py-4">
            <img src={`/images/${popup_product.img}`} alt="" />
        </div>
        <div className="flex flex-col w-full p-2 bg-gray-50">
            <span className="text-2xl font-semibold">{popup_product.name}</span>
            <span className="text-base font-semibold">{popup_product.description}</span>
        </div>
        <div className="mb-6 selector"></div>
        <div className="flex flex-col mb-6">
            <span className="mb-2 ml-2 text-xl font-semibold">Choose Size</span>
            <div className="flex justify-between w-full gap-2 px-2 ">
              {popup_product.sizeOptions.map((option)=>(
                <div onClick={()=>handleChangeSize(option)} className="relative flex flex-col items-center w-full p-2 py-4 rounded shadow-sm">
                  <div className={`absolute w-4 h-4 border-2 flex items-center justify-center rounded-full top-2 left-2 ${selectedOption.value === option.value ? ('border-prim_red') : ('border-gray-300')}`}>
                    {selectedOption.value === option.value ? (<div className="w-2 h-2 rounded-full bg-prim_red"></div>) : ('')}
                  </div>
                  <img className="w-16" src={option.icon} alt="" />
                  <span className="text-sm font-bold">{option.label}</span>
                  <span className="my-2 text-xs text-yellow-600">{option.serves}</span>
                  <span className="text-sm font-bold ">{option.price}.00 {currency}</span>
                </div>
              ))}
            </div>
        </div>
        <div className="flex flex-col mb-6">
            <span className="mb-2 ml-2 text-xl font-semibold">Choose Crust</span>
            <div className="flex flex-col w-full gap-3 p-3 shadow-sm rounded-xl">
            {popup_product.crustOptions.map((option)=>(
                <div onClick={()=>handleChangeCrust(option)} className="relative flex items-center justify-between">
                  <div className="flex ">
                    
                  <img className="w-16 rounded-lg" src={option.icon} alt="" />
                  <div className="flex flex-col justify-center ml-2 ">
                  <span className="text-lg font-bold">{option.label}</span>
                  <span className="font-semibold ">{option.price}.00 {currency}</span>
                    
                  </div>
                  </div>
                  <div className={` w-4 h-4 border-2 flex items-center justify-center rounded-full top-2 left-2 ${selectedCrustOption.value === option.value ? ('border-prim_red') : ('border-gray-300')}`}>
                    {selectedCrustOption.value === option.value ? (<div className="w-2 h-2 rounded-full bg-prim_red"></div>) : ('')}
                  </div>
                </div>
              ))}
            </div>
        </div>
        <div className="flex flex-col mb-6">
            <span className="mb-2 ml-2 text-xl font-semibold">Choose Pizza Sauce</span>
            <div className="flex flex-col w-full gap-3 p-3 shadow-sm rounded-xl">
            {sauceOptions.map((option) => (
      <div key={option.value} className="relative flex items-center justify-between">
        <div className="flex">
          <img className="w-16 rounded-lg" src={option.icon} alt="" />
          <div className="flex flex-col justify-center ml-2">
            <span className="text-lg font-bold">{option.label}</span>
            <span className="font-semibold ">{option.price}.00 {currency}</span>
          </div>
        </div>

        {/* Toggle Button */}
        {sauce_selected(option) ? (
          <button
            className="p-2 text-white border rounded shadow-xs"
            onClick={() => toggle_sauce(option)}
          >
            <FiTrash color="gray" />
          </button>
        ) : (
          <button
            className="px-3 py-2 rounded"
            style={{border: '1px solid red'}}
            onClick={() => toggle_sauce(option)}
          >
            <span className="text-sm font-semibold text-prim_red">Add</span>
          </button>
        )}
      </div>
    ))}
            </div>
        </div>
        <div className="flex flex-col mb-6">
            <span className="mb-2 ml-2 text-xl font-semibold">Choose Toppings</span>
            <div className="flex flex-col w-full gap-3 p-3 pb-6 shadow-sm rounded-xl">
            {extrasOptions.map((option)=>(
                <div  className="relative flex items-center justify-between">
                  <div className="flex ">
                    
                  <img className="w-16 rounded-lg" src={option.src} alt="" />
                  <div className="flex flex-col justify-center ml-2 ">
                  <span className="text-lg font-bold">{option.title}</span>
                  <span className="font-semibold ">{option.price}.00 {currency}</span>
                    
                  </div>
                  </div>
                  {extras_selected(option) ? (
          <button
            className="p-2 text-white border rounded shadow-xs"
            onClick={() => toggle_extras(option)}
          >
            <FiTrash color="gray" />
          </button>
        ) : (
          <button
            className="px-3 py-2 rounded"
            style={{border: '1px solid red'}}
            onClick={() => toggle_extras(option)}
          >
            <span className="text-sm font-semibold text-prim_red">Add</span>
          </button>
        )}
                </div>
              ))}
            </div>
        </div>
        </div>
    )
}

export default CustomizeProduct